"use strict";

var _interopRequireDefault = require("C:/work/gitResp/pc-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCodeImg = getCodeImg;
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
var _request = _interopRequireDefault(require("@/utils/request"));
// 登录方法
function login(username, password, code, uuid, grantType) {
  var data = {
    username: username,
    password: password,
    code: code,
    uuid: uuid,
    grantType: grantType
  };
  return (0, _request.default)({
    url: '/login',
    method: 'post',
    data: data
  });
}

// 获取用户详细信息
function getInfo() {
  return (0, _request.default)({
    url: '/user/getUserData',
    method: 'get'
  });
}

// 退出方法
function logout() {
  return (0, _request.default)({
    url: '/login/logout',
    method: 'get'
  });
}

// 获取验证码
function getCodeImg(uuid) {
  return (0, _request.default)({
    url: '/login/getCaptcha',
    method: 'get',
    params: {
      uuid: uuid
    }
  });
}