"use strict";

var _interopRequireDefault = require("C:/work/gitResp/pc-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
var constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component(resolve) {
      return require(['@/views/redirect'], resolve);
    }
  }]
}, {
  path: '/login',
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/error/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/error/401'], resolve);
  },
  hidden: true
}, {
  path: '',
  component: _layout.default,
  redirect: 'index',
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/index'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: 'Dashboard',
      icon: 'dashboard',
      noCache: true,
      affix: true
    }
  }]
}, {
  path: '/user',
  component: _layout.default,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'profile',
    component: function component(resolve) {
      return require(['@/views/system/user/profile/index'], resolve);
    },
    name: 'Profile',
    meta: {
      title: '个人中心',
      icon: 'user'
    }
  }]
}, {
  path: '/dict',
  component: _layout.default,
  hidden: true,
  children: [{
    path: 'type/data/:dictId(\\d+)',
    component: function component(resolve) {
      return require(['@/views/system/dict/data'], resolve);
    },
    name: 'Data',
    meta: {
      title: '字典数据',
      icon: ''
    }
  }]
}, {
  path: '/job',
  component: _layout.default,
  hidden: true,
  children: [{
    path: 'log',
    component: function component(resolve) {
      return require(['@/views/monitor/job/log'], resolve);
    },
    name: 'JobLog',
    meta: {
      title: '调度日志'
    }
  }]
}, {
  path: '/gen',
  component: _layout.default,
  hidden: true,
  children: [{
    path: 'edit',
    component: function component(resolve) {
      return require(['@/views/tool/gen/editTable'], resolve);
    },
    name: 'GenEdit',
    meta: {
      title: '修改生成配置'
    }
  }]
}];
exports.constantRoutes = constantRoutes;
var _default = new _vueRouter.default({
  mode: 'history',
  // 去掉url中的#
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRoutes
});
exports.default = _default;