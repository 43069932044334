"use strict";

var _interopRequireDefault = require("C:/work/gitResp/pc-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _store = _interopRequireDefault(require("@/store"));
/**
* 操作权限处理
*/
var _default = {
  inserted: function inserted(el, binding, vnode) {
    var value = binding.value;
    var all_permission = "*:*:*";
    var permissions = _store.default.getters && _store.default.getters.permissions;
    if (permissions.includes('R_ADMIN')) {
      return true;
    }
    if (value && value instanceof Array && value.length > 0) {
      var permissionFlag = value;
      var hasPermissions = permissions.some(function (permission) {
        return all_permission === permission || permissionFlag.includes(permission);
      });
      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("\u8BF7\u8BBE\u7F6E\u64CD\u4F5C\u6743\u9650\u6807\u7B7E\u503C");
    }
  }
};
exports.default = _default;