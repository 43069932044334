"use strict";

var _interopRequireDefault = require("C:/work/gitResp/pc-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _qs = _interopRequireDefault(require("qs"));
var _jsBase = require("js-base64");
_axios.default.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
var service = _axios.default.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 60000,
  //处理Long 精度损失
  transformResponse: [function transformResponse(data) {
    if (typeof data === 'string') {
      try {
        var c1 = data.toString().replace(/:\d{17,}/g, ":\"@rr$&\"").replace(/@rr:\s/g, "").replace(/@rr:/g, "");
        var c2 = c1.replace(/@rr:\s/g, "").replace(/@rr:/g, "");
        data = JSON.parse(c2);
      } catch (e) {/* Ignore */}
    }
    return data;
  }]
});
// request拦截器
service.interceptors.request.use(function (config) {
  if ((0, _auth.getToken)()) {
    config.headers['Authorization'] = "Bearer ".concat((0, _auth.getToken)()); // 让每个请求携带自定义token 请根据实际情况自行修改
  } else {
    config.headers['Authorization'] = "Basic ".concat(_jsBase.Base64.encode("".concat(process.env.VUE_APP_APPLICATION_CLIENT_ID, ":").concat(process.env.VUE_APP_APPLICATION_CLIENT_SECRET)));
  }
  if (config.method === 'get') {
    // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
    config.paramsSerializer = function (params) {
      return _qs.default.stringify(params, {
        arrayFormat: 'repeat'
      });
    };
  }
  return config;
}, function (error) {
  Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(function (res) {
  var code = res.data.code;
  if (code === 401) {
    _elementUi.MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _store.default.dispatch('LogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    });
    //return Promise.reject('error');
  } else if (code !== 200) {
    _elementUi.Notification.error({
      title: res.data.message
    });
    return Promise.reject('error');
  } else {
    return res.data;
  }
}, function (error) {
  console.log('err' + error);
  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;