"use strict";

var _interopRequireDefault = require("C:/work/gitResp/pc-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("C:\\work\\gitResp\\pc-ui\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("C:\\work\\gitResp\\pc-ui\\node_modules\\core-js\\modules\\es6.promise.js");
require("C:\\work\\gitResp\\pc-ui\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("C:\\work\\gitResp\\pc-ui\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./assets/styles/element-variables.scss");
require("@/assets/styles/index.scss");
require("@/assets/styles/common.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _permission = _interopRequireDefault(require("./directive/permission"));
require("./assets/icons");
require("./permission");
var _data = require("@/api/system/dict/data");
var _config = require("@/api/system/config");
var _common2 = require("@/utils/common");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _vueParticles = _interopRequireDefault(require("vue-particles"));
// a modern alternative to CSS resets

// global css
//

// icon
// permission control

_vue.default.use(_vueParticles.default);

// 全局方法挂载
_vue.default.prototype.getEnums = _data.getEnums;
_vue.default.prototype.getDicts = _data.getDicts;
_vue.default.prototype.getConfigKey = _config.getConfigKey;
_vue.default.prototype.parseTime = _common2.parseTime;
_vue.default.prototype.resetForm = _common2.resetForm;
_vue.default.prototype.addDateRange = _common2.addDateRange;
_vue.default.prototype.addDate = _common2.addDate;
_vue.default.prototype.selectDictLabel = _common2.selectDictLabel;
_vue.default.prototype.selectDesc = _common2.selectDesc;
_vue.default.prototype.download = _common2.download;
_vue.default.prototype.handleTree = _common2.handleTree;
_vue.default.prototype.handleClose = _common2.handleClose;
_vue.default.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success"
  });
};
_vue.default.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "error"
  });
};
_vue.default.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
};

// 全局组件挂载
_vue.default.component('Pagination', _Pagination.default);
_vue.default.use(_permission.default);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size
});

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});