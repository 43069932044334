"use strict";

var _interopRequireDefault = require("C:/work/gitResp/pc-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addDate = addDate;
exports.addDateRange = addDateRange;
exports.download = download;
exports.handleClose = handleClose;
exports.handleTree = handleTree;
exports.parseTime = parseTime;
exports.praseStrEmpty = praseStrEmpty;
exports.resetForm = resetForm;
exports.selectDesc = selectDesc;
exports.selectDictLabel = selectDictLabel;
exports.sprintf = sprintf;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
var _typeof2 = _interopRequireDefault(require("C:/work/gitResp/pc-ui/node_modules/@babel/runtime/helpers/typeof.js"));
/**
 * 通用js方法封装处理
 */

var baseURL = process.env.VUE_APP_BASE_API;

// 日期格式化
function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  var format = pattern || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/');
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

// 表单重置
function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

// 添加日期范围
function addDateRange(params, dateRange) {
  var search = params;
  if (search.map) {} else {
    search.map = {};
  }
  search.map.beginTime = undefined;
  search.map.endTime = undefined;
  if (null != dateRange && '' != dateRange) {
    search.map.beginTime = this.dateRange[0];
    search.map.endTime = this.dateRange[1];
  }
  return search;
}

// 添加日期范围-加entity里
function addDate(params, dateRange) {
  var search = params;
  search.entity.beginTime = undefined;
  search.entity.endTime = undefined;
  if (null != dateRange && '' !== dateRange) {
    var startTime = ' 00:00:00';
    var endTime = ' 23:59:59';
    search.entity.beginTime = this.dateRange[0] + startTime;
    search.entity.endTime = this.dateRange[1] + endTime;
  }
  return search;
}

// 回显数据字典
function selectDictLabel(datas, value) {
  var actions = [];
  Object.keys(datas).map(function (key) {
    if (datas[key].dictValue === '' + value) {
      actions.push(datas[key].dictLabel);
      return false;
    }
  });
  return actions.join('');
}
// 回显枚举数据
function selectDesc(datas, value) {
  var desc;
  datas.forEach(function (v) {
    if (v.code === value) {
      desc = v;
    }
  });
  return desc;
}

// 通用下载方法
function download(fileName) {
  window.location.href = baseURL + "/common/download?fileName=" + encodeURI(fileName) + "&delete=" + true;
}

// 字符串格式化(%s )
function sprintf(str) {
  var args = arguments,
    flag = true,
    i = 1;
  str = str.replace(/%s/g, function () {
    var arg = args[i++];
    if (typeof arg === 'undefined') {
      flag = false;
      return '';
    }
    return arg;
  });
  return flag ? str : '';
}

// 转换字符串，undefined,null等转化为""
function praseStrEmpty(str) {
  if (!str || str == "undefined" || str == "null") {
    return "";
  }
  return str;
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
function handleTree(data, id, parentId, children, rootId) {
  id = id || 'id';
  parentId = parentId || 'parentId';
  children = children || 'children';
  rootId = rootId || 0;
  //对源数据深度克隆
  var cloneData = JSON.parse(JSON.stringify(data));
  //循环所有项
  var treeData = cloneData.filter(function (father) {
    var branchArr = cloneData.filter(function (child) {
      //返回每一项的子级数组
      return father[id] === child[parentId];
    });
    branchArr.length > 0 ? father.children = branchArr : '';
    //返回第一层
    return father[parentId] === rootId;
  });
  return treeData !== '' ? treeData : data;
}

/**
 * 防止窗口关闭方法
 */
function handleClose(done) {
  /*this.$confirm('确认关闭？')
    .then(_ => {
      done();
    })
    .catch(_ => {});*/
}