"use strict";

var _interopRequireDefault = require("C:/work/gitResp/pc-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _store = _interopRequireDefault(require("@/store"));
/**
* 角色权限处理
*/
var _default = {
  inserted: function inserted(el, binding, vnode) {
    var value = binding.value;
    var super_admin = "admin";
    var roles = _store.default.getters && _store.default.getters.roles;
    if (value && value instanceof Array && value.length > 0) {
      var roleFlag = value;
      var hasRole = roles.some(function (role) {
        return super_admin === role || roleFlag.includes(role);
      });
      if (!hasRole) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("\u8BF7\u8BBE\u7F6E\u89D2\u8272\u6743\u9650\u6807\u7B7E\u503C\"");
    }
  }
};
exports.default = _default;